import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { checkUserSession } from './redux/User/user.actions';

// components
import AdminToolbar from './site/components/AdminToolbar';

// hoc
import WithAuth from './hoc/withAuth';
import WithAdminAuth from './hoc/withAdminAuth';

// layouts
import MainLayout from './site/pages/layouts/MainLayout';
import HomePageLayout from './site/pages/layouts/HomePageLayout';
import AdminLayout from './site/pages/layouts/AdminLayout';
import DashboardLayout from './site/pages/layouts/DashboardLayout';

// pages
import Homepage from './site/pages/HomePage';
import Search from './site/pages/Search';
import Products from './site/pages/Products';
import Registration from './site/pages/Registration';
import Login from './site/pages/Login';
import Recovery from './site/pages/Recovery';
import DashBoard from './site/pages/DashBoard';
import Admin from './site/pages/Admin';
import ProductDetail from './site/pages/ProductDetail';
import Cart from './site/pages/Cart';
import Payment from './site/pages/Payment';
import Order from './site/pages/Order';
import Depositar from './site/pages/Depositar'

import './default.scss';
import Privacidade from './site/pages/Privacidade';
import Termo from './site/pages/Termo';
import ProductResults from './site/components/LotesResults/Lote/ProductResults';

const App = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());

  }, []);

  return (
    <div className="App">
      <AdminToolbar />
      <Switch>
        <Route exact path="/" render={() => (
          <HomePageLayout>
            <Homepage />
          </HomePageLayout>
        )}
        />
        <Route exact path="/search" render={() => (
          <MainLayout>
            <Search />
          </MainLayout>
        )} />
        <Route path="/search/:filterType" render={() => (
          <MainLayout>
            <Search />
          </MainLayout>
        )} />
        <Route path="/products" render={() => (
          <MainLayout>
            <Products />
          </MainLayout>
        )} />
        <Route path="/product/:productID" render={() => (
          <MainLayout>
            <ProductDetail />
          </MainLayout>
        )} />
        <Route path="/cart" render={() => (
          <MainLayout>
            <Cart />
          </MainLayout>
        )} />
        <Route path="/payment" render={() => (
          <WithAuth>
            <MainLayout>
              <Payment />
            </MainLayout>
          </WithAuth>
        )} />
        <Route path="/registro" render={() => (
          <MainLayout>
            <Registration />
          </MainLayout>
        )} />
        <Route path="/login"
          render={() => (
            <MainLayout>
              <Login />
            </MainLayout>
          )} />
        <Route path="/recover" render={() => (
          <MainLayout>
            <Recovery />
          </MainLayout>
        )} />
        <Route path="/privacy" render={() => (
          <MainLayout>
            <Privacidade />
          </MainLayout>
        )} />
        <Route path="/lote/:loteId" render={() => (
          <MainLayout>
            <ProductResults />
          </MainLayout>
        )} />
        <Route path="/terms" render={() => (
          <MainLayout>
            <Termo />
          </MainLayout>
        )} />
        <Route path="/dashboard" render={() => (
          <WithAuth>
            <DashboardLayout>
              <DashBoard />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/depositar" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Depositar />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/order/:orderID" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Order />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/admin" render={() => (
          <WithAdminAuth>
            <AdminLayout>
              <Admin />
            </AdminLayout>
          </WithAdminAuth>
        )} />
      </Switch>
    </div>
  );
}

export default App;