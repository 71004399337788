import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiInstance } from '../../../Utils';
import { auth } from './../../../firebase/utils'
import Line from './Line';
import QuantityLine from './QuantityLine';

const mapState = ({ ordersData }) => ({
  orderDetails: ordersData.orderDetails
});

const Order = () => {
  const history = useHistory();
  const { orderID } = useParams();
  const [orders, setOrders] = useState([]);
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user)
        console.log(user)
        apiInstance.post('user_dashboard', { userUID: user.uid }).then(res => {
          setOrders(res.data.pedidos);
        })
        setLoading(false)
      } else {
        history.push('/login');
      }
    });
  }, []);

  return (
    <div style={{'height': '700px'}}>
      <h1>
        ID do pedido: #{orderID}
      </h1>
      <a href={orders?.find(a => a.id === orderID)?.boleto} target="_blank" style={{ 'text-decoration': 'none', 'color': '#5063f0', 'margin-bottom': '15px' }}>Link para o Boleto!</a>
      <table className="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantidade</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(orders) ?
            <Line { ...orders?.find(a => a.id === orderID) } />
            :
            <tr>
              <td colspan="3">Nenhum item nesse pedido</td>
            </tr>
          }
        </tbody>
        {Array.isArray(orders) && [
          <tbody>
            <tr>
              <td colspan="2">Total</td>
              <td colspan="1">{orders?.find(a => a.id === orderID)?.total}</td>
            </tr>
            <tr>
              <td colspan="2">Quantidade</td>
              <QuantityLine { ...orders?.find(a => a.id === orderID) } />
            </tr>
          </tbody>
        ]}
      </table>
    </div>
  )

}

export default Order;
