import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiInstance } from '../../../Utils';
import OrderHistory from './../../components/OrderHistory';
import { auth } from './../../../firebase/utils'

import './styles.scss';
import { useHistory } from 'react-router-dom';



const Dashboard = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = useState({})
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);




  useEffect(() => {
    setLoading(true);
    auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user)
        console.log(user)
        apiInstance.post('user_dashboard', { userUID: user.uid }).then(res => {
          setOrders(res.data.pedidos)
        })
        setLoading(false)
      } else {
        history.push('/login');
      }
    });



  }, []);

  return (
    <div style={{'height': '700px'}}>
      <h1>
        Extrato de operações
      </h1>
      <OrderHistory orders={orders} />
    </div>
  );
};

export default Dashboard;