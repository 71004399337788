import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, useParams } from 'react-router-dom';
import { fetchAllProductsStart } from '../../../redux/Products/products.actions';
import { FaCartArrowDown, FaCartPlus, FaSearch, FaMoneyBillWave } from "react-icons/fa";
import './styles.scss';

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';

import Product from '../LotesResults/Lote/ProductResults/Product';

import Vinho from "../../assets/vinho.jpeg";
import Impressora from "../../assets/impressora.jpeg";
import Iphone from "../../assets/iphone.jpeg";
import BusValidator from "../../assets/busvalidator.jpeg";

const mapState = ({ productsData }) => ({
  products: productsData.products
});

const ProductsResults = ({ }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { products } = useSelector(mapState);
  const [loading, setLoading] = useState(false)

  const { data } = products;

  useEffect(() => {
    dispatch(
      fetchAllProductsStart({products})
    );
  }, []);

  return (
    <section className="margin-menu products-section">
      <Container>
        <Row>
          <Col>
            <Row>
              {(Array.isArray(data) && data.length > 0) && data.map((product, index) => {
                return (<Product key={index} {...product} />);
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProductsResults;