export const firebaseConfig={
    apiKey: "AIzaSyDVGNTVa9xz0QZwhzXvrPO--lnrqhSIYAo",

    authDomain: "joinbid-eb45e.firebaseapp.com",
  
    projectId: "joinbid-eb45e",
  
    storageBucket: "joinbid-eb45e.appspot.com",
  
    messagingSenderId: "455168099344",
  
    appId: "1:455168099344:web:282a5f0556b1189aa03695",
  
    measurementId: "G-H7C0L04GZN"
  
}