import { firestore } from './../../firebase/utils';

export const handleAddProduct = product => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('products')
      .doc()
      .set(product)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchProducts = ({ loteId, persistProducts=[] }) => {
  return new Promise((resolve, reject) => {
    let ref = firestore.collection('products').where('stripe_metadata_lote_id','==',loteId);
    ref
      .get()
      .then(snapshot => {
        const data = [
          ...persistProducts,
          ...snapshot.docs.map(doc => {
 
              return {
                ...doc.data(),
                documentID: doc.id
              }
            }
          
          )
        ];

        resolve({
          data
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleFetchAllProducts = () => {
    return new Promise((resolve, reject) => {
    let ref = firestore.collection('products').where('active','not-in',[false]);
    ref
      .get()
      .then(snapshot => {
        const data = [
          ...snapshot.docs.map(doc => {
            if(doc.id!=='prod_KEIN1EBCd27y7u'&&doc.id!=='prod_KJ2PN7eUVhGTqw')
              return {
                ...doc.data(),
                documentID: doc.id
              }
            
          })
        ];

        resolve({
          data
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleDeleteProduct = documentID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('products')
      .doc(documentID)
      .delete()
      .then(() => {
        console.log(documentID, 2)
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchProduct = (productID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('products')
      .doc(productID)
      .get()
      .then(snapshot => {

        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: productID
          });
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}