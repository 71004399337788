import React from 'react';
import {
  TableContainer, Table, TableHead,
  TableRow, TableBody, TableCell
} from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import './styles.scss'
const columns = [
  {
    id: 'data_criacao',
    lable: 'Data da compra'
  },
  {
    id: 'total',
    lable: 'Valor (R$)'
  },
  {
    id: 'status',
    lable: 'Status do pagamento'
  },
  {
    id: 'conferir',
    lable: ''
  }
];

const styles = {
  fontSize: '16px',
  cursor: 'pointer',
  width: '10%'
};

const formatText = (columnName, columnValue) => {
  switch (columnName) {
    case 'total':
      return `R$${columnValue/100}`;
    case 'data_criacao':
      return moment(columnValue).format('DD/MM/YYYY')
    case 'status':
      if(columnValue==='succeeded'){
        return'pago'
      }else{
        return 'aguardando pagamento' 
      }
    default:
      return columnValue;
  }
};

const OrderHistory = ({ orders }) => {
  const history = useHistory();

  return (
    <TableContainer>
      <Table>

        <TableHead>
          <TableRow>
            {columns.map((column, pos) => {
              const { lable } = column;

              return (
                <TableCell
                  key={pos}
                  style={styles}
                >
                  {lable}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>

        <TableBody>

          {(Array.isArray(orders) && orders.length > 0) && orders.map((row, pos) => {
            const  documentID  = row.id;

            return (
              <TableRow
                key={pos}
                onClick={() => history.push(`/order/${documentID}`)}
              >

                {columns.map((column, pos) => {
                  const columnName = column.id;
                  const columnValue = row[columnName];
                  const formattedText = formatText(columnName, columnValue);
                  if(column.id==='conferir'){
                    return(
                    <TableCell
                    key={pos}
                    style={styles}
                  >
                    <a className="link">Detalhes</a>
                  </TableCell>
                )
                  }

                  return (
                    <TableCell
                      key={pos}
                      style={styles}
                    >
                      {formattedText}
                    </TableCell>
                  )
                })}

              </TableRow>
            )
          })}

        </TableBody>

      </Table>
    </TableContainer>
  )
}

export default OrderHistory;