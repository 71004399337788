import React from 'react';
import SignIn from '../../components/SignIn';
import './styles.scss'

export default function Login(props){
        return(
            <div>
                <SignIn/>
            </div>
        )
}