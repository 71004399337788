import React, { Component } from 'react'
import ButtonForm from './../forms/ButtonForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import './styles.scss'
import { signInWithGoogle, auth } from './../../../firebase/utils'
import FormInput from './../forms/FormInput'
import { FaEnvelope } from "react-icons/fa";

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';

library.add(fab)


const initialState = {
  email: '',
  password: ''
}

export default class Recover extends Component {

  constructor(props) {
    super(props)
    this.state = {
      ...initialState
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e) {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { email, password } = this.state
    try {
      await auth.signInWithEmailAndPassword(email, password)
      this.setState({
        ...initialState
      })
    } catch (error) {
      //console.log(error)
    }
  }
  render() {
    const { email, password } = this.state
    return (
      <section className="margin-menu">
        <div className="text-center">
          <h2>Resetar senha</h2>
        </div>
        <form onSubmit={this.handleSubmit}>
          <Container>
            <Row>
              <Col>
                <div>
                  <FormInput
                    type='email'
                    name='email'
                    value={email}
                    placeholder='Email para envio do link de reset da senha'
                    onChange={this.handleChange} />
                </div>
                <div>
                  <Button variant="primary" id='btn'>Enviar link</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      </section>
    )
  }
}