import React from 'react';

const Line = (order) => {
  return order?.items ?
    order?.items?.map(val => {
      return (
        <tr>
          <td>{val.description}</td>
          <td>{val.quantity}</td>
          <td>{val.total}</td>
        </tr>
      );
    })
    :
    <tr>
      <td colspan="3">Nenhum item nesse pedido</td>
    </tr>
};

export default Line;
