import lotesTypes from './lotes.types';

export const addLotesStart = loteData => ({
  type: lotesTypes.ADD_NEW_LOTE_START,
  payload: loteData
});

export const fetchLotesStart = (filters={}) => ({
  type: lotesTypes.FETCH_LOTES_START,
  payload: filters
});
export const fetchLotesDestaqueStart = (filters={}) => ({
  type: lotesTypes.FETCH_LOTES_DESTAQUE_START,
  payload: filters
});

export const setLotes = lotes => ({
  type: lotesTypes.SET_LOTES,
  payload: lotes
});

export const deleteLotesStart = lotesId => ({
  type: lotesTypes.DELETE_LOTES_START,
  payload: lotesId
});

export const fetchLoteStart = lotesId => ({
  type: lotesTypes.FETCH_LOTES_START,
  payload: lotesId
});

