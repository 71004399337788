import { auth } from '../../firebase/utils';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setLotes, setLote,fetchLoteStart } from './lotes.actions';
import {  handleFetchLotes,
 handleDeleteLotes,
 handleFetchLotesDestaque} from './lotes.helpers';
import lotesTypes from './lotes.types';

export function* fetchLotesDestaque() {
  try {
    const lotes = yield handleFetchLotesDestaque();
    yield put(
      setLotes(lotes)
    );

  } catch (err) {
    // console.log(err);
  }
}
export function* onFetchLotesDestaqueStart() {
  yield takeLatest(lotesTypes.FETCH_LOTES_DESTAQUE_START, fetchLotesDestaque);
}


export function* fetchLotes() {
  try {
    const lotes = yield handleFetchLotes();
    yield put(
      setLotes(lotes)
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchLotesStart() {
  yield takeLatest(lotesTypes.FETCH_LOTES_START, fetchLotes);
}

export function* deleteLotes({ payload }) {
  try {
    yield handleDeleteLotes(payload);
    yield put (
      fetchLoteStart()
    );

  } catch (err) {
    // console.log(err);
  }
}

export function* onDeleteLotesStart() {
  yield takeLatest(lotesTypes.DELETE_LOTES_START, deleteLotes);
}

export default function* lotesSagas() {
  yield all([
    call(fetchLotes),
    call(onFetchLotesStart),
    call(onDeleteLotesStart),
    call(onFetchLotesStart)
  ])
}
