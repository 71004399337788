import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import './styles.scss'
import FormInput from './../forms/FormInput'
import { FaSignInAlt } from "react-icons/fa";
import AuthWraper from './../AuthWraper'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { emailSignInStart, googleSignInStart } from './../../../redux/User/user.actions';
import { Divider } from '@material-ui/core';

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';
import Logo from "../../assets/logo.png";

library.add(fab)

const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

export default function SignIn(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector(mapState);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (currentUser) {
      resetForm();
      history.push('/');
    }

  }, [currentUser]);

  const resetForm = () => {
    setEmail('');
    setPassword('');
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(emailSignInStart({ email, password }));
  }

  const handleGoogleSignIn = () => {
    dispatch(googleSignInStart());
  }

  const configAuthWrapper = {
    headline: 'Login'
  };
  return (
    <section className="margin-menu">
      <Container className="login-container">
        <form onSubmit={handleSubmit}>
          <div className="text-center">
            <h2>Login</h2>
            <img src={Logo} className="logo-branding" />
          </div>
          <Row className="margin-top">
            <Col>
              <div>
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Email"
                  onChange={e => setEmail(e.target.value)} />
              </div>
              <div className="margin-top-button">
                <Form.Control
                  type="password"
                  name="password"
                  value={password}
                  placeholder="Senha"
                  onChange={e => setPassword(e.target.value)} />
              </div>
              <div className="margin-top-button">
                <Button variant="primary" size="lg" id='btn'>Entrar</Button>
              </div>
              <div className="margin-top-button">
                <Button variant="primary" size="lg" id="btnCadastro" onClick={() => window.location.pathname = '/registro'}>Cadastre-se</Button>
              </div>
              <div className="margin-top-button">
                <Button variant="danger" size="lg" onClick={handleGoogleSignIn}>
                  <FontAwesomeIcon icon={['fab', 'google']} /> Entrar com google
                </Button>
              </div>
              <div className="text-center margin-top-button">
                <Col className="links">
                  <Link className="link-password" to="/recover"> Recuperar senha</Link>
                </Col>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </section>
  )

}