import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { fetchLotesStart } from './../../../../../redux/Lotes/lotes.actions';
import { fetchProductsStart } from './../../../../../redux/Products/products.actions';
import { FaCartArrowDown, FaEye, FaCartPlus, FaSearch, FaMoneyBillWave } from "react-icons/fa";
import Product from './Product';

import LoadMore from './../../../LoadMore';
import './styles.scss';

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';

import Vinho from "../../../../assets/vinho.jpeg";
import Impressora from "../../../../assets/impressora.jpeg";
import Iphone from "../../../../assets/iphone.jpeg";
import BusValidator from "../../../../assets/busvalidator.jpeg";

const mapState = ({ productsData, lotesData }) => ({
  products: productsData.products,
  lotes: lotesData.lotes
});

const ProductResults = ({ }) => {
  const dispatch = useDispatch();
  const { loteId } = useParams();
  const { products, lotes } = useSelector(mapState);

  const { data } = lotes;
  const productsData = products.data;

  useEffect(() => {
    dispatch(
      fetchProductsStart({ loteId }),
      fetchLotesStart(lotes)
    )
  }, [loteId]);


  return (
    <section className="margin-menu products-section">
      <Container>
        {(Array.isArray(data) && data.length > 0) && data.map((lote, pos) => {
          if (lote.documentID === loteId) {
            const finalDate = lote.data_final.substring(0, 10).split('-');
            const endDate = new Date(finalDate[2], finalDate[1], finalDate[0]);
            return (
              <Row>
                <div className="lote-title">
                  <h1>Edital: {lote.edital}</h1>
                  <h6>Cidade: {lote.cidade}</h6>
                </div>
                <Col xs={8}>
                  <div className="img"></div>
                </Col>
                <Col className="lote-details">
                  <div>
                    <h7><strong>Data de encerramento:</strong>{endDate.toLocaleDateString('pt-BR')}</h7>
                  </div>
                  <hr />
                  <div>
                    <h7><strong>itens:</strong></h7>
                    <ul>
                      {Array.isArray(productsData) && productsData.length > 0 ? (Array.isArray(productsData) && productsData.length > 0) && productsData.map((product, pos) => {
                        return (
                          <li>{product?.description}</li>
                        );
                      }) : <li>Nenhum produto disponível para esse lote</li>}
                    </ul>
                  </div>
                </Col>
              </Row>
            );
          }
        })}
        <Row>
          <h3>Produtos disponíveis no Lote:</h3>
        </Row>
        <Row>
          {Array.isArray(productsData) && productsData.length > 0 ? (Array.isArray(productsData) && productsData.length > 0) && productsData.map((product, pos) => {
            return (
              <Product key={pos} {...product} />
            );
          }) : <h3>Nenhum produto disponível para esse lote</h3>}
        </Row>
      </Container>
    </section>
  );
};

export default ProductResults;