import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, useParams } from 'react-router-dom';
import { fetchLotesStart } from './../../../redux/Lotes/lotes.actions';
import Lote from './Lote';
import './styles.scss';

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';

const mapState = ({ lotesData }) => ({
  lotes: lotesData.lotes
});

const LotesResults = ({ }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { lotes } = useSelector(mapState);
  const [loading, setLoading] = useState(false)

  const { data, queryDoc, isLastPage } = lotes;

  useEffect(() => {
    setLoading(true)
    dispatch(
      fetchLotesStart(lotes)
    );
    setLoading(false)
  }, []);


  if (!Array.isArray(data)) return null;
  if (data.length < 1) {
    return (
      <div className="lotes">
        <p>
          No search results.
        </p>
      </div>
    );
  }


  const handleLoadMore = () => {
    dispatch(
      fetchLotesStart({
        startAfterDoc: queryDoc,
        persistLotes: data
      })
    );
  };


  const configLoadMore = {
    onLoadMoreEvt: handleLoadMore,
  };

  return (
    // <div className="lotes">

    //   <h1>
    //     Confira os próximos lotes da Receita Federal
    //   </h1>

    //   <div className="loteResults">
    //     {loading&&<Spinner style={{ width: '10rem', height: '10rem',color:'white' }} />}
    //     {(Array.isArray(data) && data.length > 0) && data.map((lote, index) => {
    //       const {
    //         cidade,
    //         destaque,
    //         edital,
    //         tipo
    //       } = lote;
    //       const configLotes = {
    //         ...lote
    //       };
    //       return (
    //         <Lote key={index} {...configLotes} />
    //       );

    //     })}

    //   </div>


    // </div>
    <section className="margin-menu products-section">
      <Container>
        <Row>
          <Col>
            <Row className="margin-bottom">
              {(Array.isArray(data) && data.length > 0) && data.map((lote, index) => {
                return (<Lote col="3" key={index} {...lote} />);
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LotesResults;