import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addProduct } from './../../../../../../redux/Cart/cart.actions';
import { FaCartArrowDown, FaEye, FaCartPlus, FaSearch, FaMoneyBillWave } from "react-icons/fa";

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';

const Product = (product) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    documentID,
    description,
    images,
    stripe_metadata_preco,
    name,
    metadata,
    unit_label
  } = product;

  if (!documentID || !images || !name) return null;

  const configAddToCartBtn = {
    type: 'button'
  };

  const handleAddToCart = (product) => {
    if (!product) return;
    dispatch(
      addProduct(product)
    );
    history.push('/cart');
  };
  let i = 0;
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <Col xs={3}>
      <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" src={images[0]} />
        <Card.Body>
          <p><strong>Produto</strong></p>
          <Card.Title>{name}</Card.Title>
          <Card.Text>
            <h6>Detalhes do Produto</h6>
            <p>{description}</p>
            <hr></hr>
            <p>Valor:</p>
            <h5>{formatter.format(stripe_metadata_preco)}</h5>
          </Card.Text>
          <button className="round-button" onClick={() => handleAddToCart(product)}><FaCartArrowDown></FaCartArrowDown></button>
          <button className="round-button visualize">
            <Link to={{ pathname: `/product/${documentID}` }}>
              <FaEye></FaEye>
            </Link>
          </button>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default Product;