import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';

const Lotes = (lote) => {
  const history = useHistory();

  const {
    documentID,
    cidade,
    destaque,
    edital,
    tipo,
    img,
    col
  } = lote;

  if (!documentID | !edital) return null;

  const configVisitarLoteBtn = {
    type: 'button'
  };

  let i = 0
  return (
    <Col xs={col}>
      <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" src={img} />
        <Card.Body>
          <p><strong>Lote</strong></p>
          <Card.Title>{edital}</Card.Title>
          <Card.Text>
            <h6>Cidade:</h6>
            <p>{cidade}</p>
          </Card.Text>
          <Card.Footer>
            <Button variant="primary" size="lg">
              <Link to={{ pathname: `/lote/${documentID}` }}>
                Visualizar
              </Link>
            </Button>
          </Card.Footer>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default Lotes;