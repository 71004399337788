import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectCartItems, selectCartTotal } from './../../../redux/Cart/cart.selectors';
import { removeCartItem, addProduct, reduceCartItem } from './../../../redux/Cart/cart.actions';
import { } from "./../../../redux/User/user.actions";
import { createStructuredSelector } from 'reselect';
import './styles.scss';
import ButtonForm from './../forms/ButtonForm';
import Item from './Item';
import { apiInstance } from '../../../Utils';
import { useState } from 'react';
import { Spinner } from 'reactstrap';
import LoadingSpinner from '../LoadingSpinner';
import { FaTrash } from "react-icons/fa";

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';

import Vinho from "../../assets/vinho.jpeg";
import Impressora from "../../assets/impressora.jpeg";
import Iphone from "../../assets/iphone.jpeg";
import BusValidator from "../../assets/busvalidator.jpeg";

const mapState = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
});

const userState = (state) => ({
  currentUser: state.user.currentUser
})

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const Checkout = ({ }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(userState)
  const history = useHistory();
  const { cartItems, total } = useSelector(mapState);
  const [loading, setLoading] = useState(false)

  const handleRemoveCartItem = (documentID) => {
    dispatch(
      removeCartItem({
        documentID
      })
    );
  }
  
  const handleAddProduct = (product) => {
    dispatch(
      addProduct(product)
    )
  }
  
  const handleReduceItem = (product) => {
    dispatch(
      reduceCartItem(product)
    );
  }


  const errMsg = 'Não há produtos no carrinho.';
  const createCheckout = async () => {
    setLoading(true)
    await apiInstance.post('/create_checkout', {
      customerId: currentUser.stripeId,
      items: cartItems
    }).then((x) => {
      setLoading(false)
      const aLink = document.getElementById('link-to-checkout');
      aLink.setAttribute('href', x.data.ckechout);
      aLink.click();
    })

  }
  if (!loading) {
    return (
      <section class="margin-menu">
        <a id="link-to-checkout" target="_blank"></a>
        <Container>
          <div className="text-center">
            <h2>Carrinho</h2>
          </div>
          <Row>
            <Col xs={2} className="text-center">
              <h3>Produto</h3>
            </Col>
            <Col xs={6} className="text-center">
              <h3>Descrição</h3>
            </Col>
            <Col xs={2} className="text-center">
              <h3>Valor (R$)</h3>
            </Col>
            <Col xs={2} className="text-center">
              <h3>Ações</h3>
            </Col>
          </Row>
          {cartItems.length > 0 ?
            (
              cartItems.map((item, pos) => {
                return (
                  <Row>
                    <Col xs={2} className="text-center">
                      { item.images ? <img className="cart-img" src={item.images[0]} /> : <img /> }
                    </Col>
                    <Col xs={6} className="text-left">
                      <p>{item.description}</p>
                    </Col>
                    <Col xs={2} className="text-center">
                      <h3>{formatter.format(item.stripe_metadata_preco)}</h3>
                    </Col>
                    <Col xs={2} className="text-center">
                      <Row>
                        <Col>
                          <Button onClick={() => handleReduceItem(item)} variant="danger">-</Button>
                        </Col>
                        <Col className="text-center">{item.quantity}</Col>
                        <Col>
                          <Button onClick={() => handleAddProduct(item)} variant="primary">+</Button>
                        </Col>
                        <Col>
                          <Button onClick={() => handleRemoveCartItem(item.documentID)} variant="danger"><FaTrash /></Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })
            ) :
            (
              <Row>
                <div className="text-center">
                  <h3>Não há itens no carrinho</h3>
                </div>
              </Row>
            )}
          <Row>
            <Col xs={10} className="text-right">Total de Itens:</Col>
            <Col xs={2} className="text-left">{cartItems.reduce((accumulator, a) => {
              return accumulator + a.quantity;
            }, 0)}</Col>
          </Row>
          <Row>
            <Col xs={10} className="text-right">Total (R$):</Col>
            <Col xs={2} className="text-left">{formatter.format(cartItems.reduce((accumulator, a) => {
              return accumulator + (a.stripe_metadata_preco * a.quantity);
            }, 0))}</Col>
          </Row>
        </Container>
        <Container>
          <Row></Row>
          <Row></Row>
          <Row>
            <Col></Col>
            <Col>
              <Button onClick={() => history.goBack()} variant="default">Continuar Comprando</Button>
            </Col>
            <Col>
              {currentUser && [
                <Button onClick={() => createCheckout()} variant="primary">Prosseguir para o Pagamento</Button>
              ]}
              {!currentUser && [
                <Link to="/login" className="btn btn-primary">Login</Link>
              ]}
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </section>
    );
  } else {
    return (<LoadingSpinner />)
  }
};

export default Checkout;
