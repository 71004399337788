import React from 'react';
import LotesResults from './../../components/LotesResults';

const Search = ({ }) => {
  return (
    <div className="searchPage">
      <LotesResults />
    </div>
  );
};

export default Search;