import React, { useState } from 'react'
import "./styles.scss";
import AuthWraper from './../AuthWraper'
import FormInput from './../forms/FormInput'
import ButtonForm from './../forms/ButtonForm'
import { FaEnvelope } from "react-icons/fa";
import { auth } from './../../../firebase/utils'
import { withRouter } from 'react-router-dom'

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Label
} from 'react-bootstrap';

function EmailPassword(props) {

  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])

  const reset = () => {
    setEmail('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {

      const config = {
        url: 'https://localhost:3000/login'
      }
      await auth.sendPasswordResetEmail(email, config).then(() => {
        alert('Email enviado')
        props.history.push('/login')
      })
        .catch(() => {
          const err = ['email não encontrado, faça seu cadastro']
          setErrors(err)
        })
    } catch (error) {
      //console.log(error)
    }

  }
  const configAuthWraper = {
    headline: 'Resetar senha'
  }
  return (
    <section className="margin-menu">
      <div className="text-center">
        <h2>Resetar senha</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <Container className="login-container">
          <Row>
            <Col>
              <div>
                <Form.Control
                  type='email'
                  name='email'
                  value={email}
                  placeholder='Email'
                  onChange={e => setEmail(e.target.value)} />
              </div>
              <div className="margin-top-button">
                <Button variant="primary" size="lg" type="submit">Enviar link</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </form>
    </section>
  )

}
export default withRouter(EmailPassword)