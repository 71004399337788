import React from 'react';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu'
import { useWindowDimensions } from "./../../../Utils";
export default function MainLayout(props){
    const { height, width } = useWindowDimensions();
        return(
        <div>
            {width>=980?<Menu {...props}/>:<Menu {...props}/>}
            <div className='main'>
                {props.children}
            </div>

            {width>=980?<Footer/>:<Footer/>}
        </div>
          
    )
}