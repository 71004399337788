import { firestore} from '../../firebase/utils';

export const handleFetchLotesDestaque = ()=>{
  return new Promise((resolve, reject) => {
    let ref = firestore.collection('lotes');
    ref
      .where('destaque','==',true).get()
      .then(snapshot => {
        const totalCount = snapshot.size;

        const data = [
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleFetchLotes = () => {
  return new Promise((resolve, reject) => {
    let ref = firestore.collection('lotes');
    ref
      .get()
      .then(snapshot => {
        const data = [
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({
          data
        });
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const handleDeleteLotes = documentID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('lotes')
      .doc(documentID)
      .delete()
      .then(() => {
        console.log(documentID, 2)
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}

export const handleFetchLote = (loteId) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('lotes')
      .doc(loteId)
      .get()
      .then(snapshot => {

        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: loteId
          });
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}
