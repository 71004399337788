import React, { useState, useEffect } from "react";
import "./styles.scss";
import FormInput from "./../forms/FormInput";
import ButtonForm from "./../forms/ButtonForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signUpUserStart, googleSignInStart } from './../../../redux/User/user.actions';
import AuthWraper from "./../AuthWraper";
import Logo from "../../assets/logo.png";

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Label
} from 'react-bootstrap';

library.add(fab);
const mapState = ({ user }) => ({
  currentUser: user.currentUser,
  userErr: user.userErr
});

export default function Signup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser, userErr } = useSelector(mapState);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (currentUser) {
      reset();
      history.push('/');
    }

  }, [currentUser]);

  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }

  }, [userErr]);
  const handleGoogleSignIn = () => {
    dispatch(googleSignInStart());
  }

  const reset = () => {
    setDisplayName('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setErrors([]);
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    dispatch(signUpUserStart({
      displayName,
      email,
      password,
      confirmPassword
    }));
  }

  return (
    <section class="margin-menu">
      <Container className="login-container">
        <div className="text-center">
          <h2>Cadastre-se</h2>
            <img src={Logo} className="logo-branding" />
        </div>
        <div className="formwrap">
          {errors.length > 0 && (
            <ul>
              {errors.map((err, index) => {
                return <li key={index}>{err}</li>;
              })}
            </ul>
          )}
        </div>
        <Row>
          <Col>
            <form onSubmit={handleFormSubmit}>
              <div>
                <Form.Control
                  type="text"
                  name="displayName"
                  value={displayName}
                  placeholder="Nome"
                  onChange={(e) => setDisplayName(e.target.value)} />
              </div>
              <div className="margin-top-button">
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="margin-top-button">
                <Form.Control
                  type="password"
                  name="password"
                  value={password}
                  placeholder="Senha"
                  onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="margin-top-button">
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  placeholder="Confirme a senha"
                  onChange={(e) => setConfirmPassword(e.target.value)} />
              </div>
              <div className="margin-top-button">
                <Button variant="primary" type="submit">
                  Registrar-se
              </Button>
              </div>
            </form>
            <div className="margin-top-button">
              <form>
                <Button variant="danger" onClick={handleGoogleSignIn}><FontAwesomeIcon icon={["fab", "google"]} /> Entrar com google</Button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
