import React from 'react';
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import { useWindowDimensions } from '../../../Utils';

export default function HomePageLayout(props){
    const { height, width } = useWindowDimensions();
    return(
        <div className='fullHeight'>
            {width>=980?<Menu {...props}/>:<Menu {...props}/>}
            {props.children}
            
            {width>=980?<Footer/>:<Footer/>}
        </div>
          
    )
}