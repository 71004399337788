import React from 'react';
import ButtonForm from './../../components/forms/ButtonForm';

const LoadMore = ({
  onLoadMoreEvt = () => { },
}) => {
  return (
    <ButtonForm onClick={() => onLoadMoreEvt()}>
      Mais...
    </ButtonForm>
  );
};

export default LoadMore;