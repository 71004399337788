import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import './styles.scss'
import Logo from "../../assets/logo.png";

import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

library.add(fab)
export default function Footer(props) {
  return (
    <section>
      <footer className='footer'>
        <Container>
          <Row className="justify-content-md-left">
            <Col xs={3}>
              <img src={Logo} />
            </Col>
            <Col className="margin-top">
              <h2>Links Úteis</h2>
              <Row>
                <Col>
                  <ul>
                    <li>
                      <Link to="/search">Lotes</Link>
                    </li>
                    <li>
                      <Link to="/products">Produtos</Link>
                    </li>
                    <li>
                      <Link>FAQ</Link>
                    </li>
                    <li>
                      <Link>Fale com a gente</Link>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li>
                      <Link>Termos de uso</Link>
                    </li>
                    <li>
                      <Link>Ajuda</Link>
                    </li>
                    <li>
                      <Link>Quem Somos</Link>
                    </li>
                    <li>
                      <Link>Notícias</Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs={12} className="text-center">
              <p>&copy; 2021 - Todos os direitos reservados à marca JoinBid.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </section>
  )
}