import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signOutUserStart } from './../../../redux/User/user.actions';

import Menu from './../../components/Menu';
import VerticalNav from './../../components/VertivalNav';
import Footer from './../../components/Footer';

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';

const DashBoardLayout = props => {
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(signOutUserStart());
  };

  return (
    <section className="margin-menu">
      <Menu {...props} />
      <Container>
        <Row>
          <Col xs={3}>
            <aside>
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link className="nav-title">Menu</Nav.Link>
                <Link className="nav-link" to="/dashboard">Dashboard</Link>
                <Link className="nav-link" to="/depositar">Depositar Créditos</Link>
              </Nav>
            </aside>
          </Col>
          <Col>
            {props.children}
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default DashBoardLayout;