import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from './../firebase/utils';



const useAuth = props => {
  const [user,setUser] = useState({})
 
  const history = useHistory();

  useEffect(() => {
     auth.onAuthStateChanged(user=>{
      if(user){
        setUser(user)
      }else{
        setUser(null)
      }
    });
    if (!user) {
      history.push('/login');
    }

  }, [user]);

  return user;
};

export default useAuth;
