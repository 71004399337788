import lotesType from './lotes.types';

const INITIAL_STATE = {
  lotes: [],
  lote: {},
};

const lotesReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case lotesType.SET_LOTES:
      return {
        ...state,
        lotes: action.payload
      }
    case lotesType.SET_LOTE:
      return {
        ...state,
        lote: action.payload
      }
    default:
      return state;
  }
};

export default lotesReducer;