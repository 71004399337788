import "./styles.scss";
import { connect } from "react-redux";
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signOutUserStart } from './../../../redux/User/user.actions';
import { selectCartItemsCount } from './../../../redux/Cart/cart.selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faShoppingCart, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { Badge } from 'reactstrap'
import { apiInstance } from './../../../Utils'
import Logo from "../../assets/output-onlinepngtools.png"

import { Navbar, Container, Nav } from 'react-bootstrap';

const mapState = (state) => ({
  currentUser: state.user.currentUser,
  totalNumCartItems: selectCartItemsCount(state)
});

export function Menu(props) {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(false);
  const dispatch = useDispatch();
  const { currentUser, totalNumCartItems } = useSelector(mapState);

  const userDashboard = async () => {
    await apiInstance.post('/user_dashboard', {
      user: currentUser
    }).then((x) => {
      window.location.assign(x.data.url)
    }).catch(err => console.log(err))

  }

  const signOut = () => {
    dispatch(signOutUserStart());
  };


  useEffect(() => {
    setActiveMenu(false);
  }, [location]);

  return (
    <Navbar className="nav-transparent">
      <Container>
        <Navbar.Brand href="/">JoinBid</Navbar.Brand>
        <Nav className="me-auto">
          <Link className="nav-link" to="/search">Lotes</Link>
        </Nav>
        <Nav className="me-auto">
          <Link className="nav-link" to="/products">Produtos</Link>
        </Nav>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Link to="/cart" className="nav-link">
              {totalNumCartItems} <FontAwesomeIcon icon={faShoppingCart} size='lg'></FontAwesomeIcon>
            </Link>
            {currentUser && [
              <li key={1}>
                <Link to='/dashboard' className="nav-link">Conta</Link>
              </li>,
              <li key={2}>
                <Nav.Link onClick={() => signOut()}>Sair</Nav.Link> 
              </li>
            ]}
            {!currentUser && [
              <li key={2}>
                <Link to="/login" className="nav-link">Login</Link>
              </li>
            ]}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

Menu.defaultProps = {
  currentUser: null,
};
const mapStateToProps = ({ user }) => ({ currentUser: user.currentUser });
export default connect(mapStateToProps, null)(Menu);
