const productsTypes = {
    ADD_NEW_LOTE_START: 'ADD_NEW_LOTES_START',
    FETCH_PRODUCTS_START: 'FETCH_LOTES_START',
    SET_LOTE:'SET_LOTE',
    SET_LOTES: 'SET_LOTES',
    DELETE_LOTES_START: 'DELETE_LOTES_START',
    FETCH_LOTES_START: 'FETCH_LOTES_START',
    FETCH_LOTES_DESTAQUE_START: 'FETCH_LOTES_DESTAQUE_START',
  };
  
  export default productsTypes;