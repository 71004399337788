import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProductStart, fetchProductsStart, deleteProductStart } from './../../../redux/Products/products.actions';
import Modal from './../../components/Modal';
import FormInput from './../../components/forms/FormInput';
import FormSelect from './../../components/forms/FormSelect';
import ButtonForm from './../../components/forms/ButtonForm';
import LoadMore from './../../components/LoadMore';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { apiInstance } from "../../../Utils";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './styles.scss';

const mapState = ({ productsData }) => ({
  products: productsData.products
});

const Admin = props => {
  const { products } = useSelector(mapState);
  const dispatch = useDispatch();
  const [hideModal, setHideModal] = useState(true);
  const [torra, setTorra] = useState('');
  const [name, setName] = useState('');
  const [preco, setPreco] = useState(0);
  const [microlote, setMicrolote] = useState(false);
  const [moagem, setMoagem] = useState('')
  const [peso, setPeso] = useState(0);
  const [description , setDescription] = useState('');
  const [tipo, setTipo] = useState('');
  const { data, queryDoc, isLastPage } = products;

  useEffect(() => {
    dispatch(
      fetchProductsStart()
    );
  }, []);


  const toggleModal = () => setHideModal(!hideModal);

  const configModal = {
    hideModal,
    toggleModal
  };

  const resetForm = () => {
    setHideModal(true);
    setName('');
    setPreco(0);
    setMoagem('')
    setTorra('')
    setDescription('')
    setMicrolote(false)
    setTipo('')
    setPeso(0)

  };

  const handleSubmit = e => {
    e.preventDefault();

   
    apiInstance.post('/admin/create_product',{
      name: name,
      active: true,
      description:description,
      metadata:{
        torra:torra,
        tipo:tipo,
        preco:preco,
        peso:peso,
        moagem:moagem,
        microlote:microlote
      }
    })
      resetForm();

  };

  const handleLoadMore = () => {
    dispatch(
      fetchProductsStart({
        startAfterDoc: queryDoc,
        persistProducts: data
      })
    );
  };

  const configLoadMore = {
    onLoadMoreEvt: handleLoadMore,
  };

  return (
    <div className="admin">

      <div className="callToActions">
        <ul>
          <li>
            <ButtonForm onClick={() => toggleModal()}>
              Novo produto
            </ButtonForm>
          </li>
        </ul>
      </div>

      <Modal {...configModal}>
        <div className="addNewProductForm">
          <form onSubmit={handleSubmit}>

            <h2>
              adicionar produto
            </h2>

            <FormSelect
              label="Torra"
              options={[{
                value: "media",
                name: "Media"
              }, {
                value: "escura",
                name: "Escura"
              },
              {
                value: "unica",
                name: "Unica"
              }]}
              handleChange={e => setTorra(e.target.value)}
            />
             <FormSelect
              label="Tipo"
              options={[{
                value: "graos",
                name: "grãos"
              }, {
                value: "moido",
                name: "Moído"
              }]}
              handleChange={e => setTipo(e.target.value)}
            />
            {tipo=="moido"&&[
               <FormSelect
               label="Moagem"
               options={[{
                 value: "fina",
                 name: "Fina"
               }, {
                 value: "media",
                 name: "Media"
               },
               {
                 value: "grossa",
                 name: "Grossa"
               }]}
               handleChange={e => setMoagem(e.target.value)}
             />
            ]}
           

            <FormInput
              label="Nome do produto"
              type="text"
              value={name}
              handleChange={e => setName(e.target.value)}
            />

            <p>Descrição</p>
            <textarea
              type="textarea"
              rows="5"
              cols="100"
              onChange={e => setDescription(e.target.value)}
            /> 
            
            <p>{'\n\n'}</p>
            <FormInput
              label="Microlote"
              type="checkbox"
              value={microlote}
              handleChange={e => setMicrolote(e.target.value)}
            />

          
            <FormInput
              label="Preço"
              type="number"
              min="0.00"
              max="10000.00"
              step="0.01"
              value={preco}
              handleChange={e => setPreco(e.target.value)}
            />
                 <FormInput
              label="peso"
              type="number"
              min="0"
              max="10000"
              step="50"
              value={peso}
              handleChange={e => setPeso(e.target.value)}
            />
          
            <br />

            <ButtonForm type="submit">
              Add product
            </ButtonForm>

          </form>
        </div>
      </Modal>

      <div className="manageProducts">

        <table border="0" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <th>
                <h1>
                  Produtos
                </h1>
              </th>
            </tr>
            <tr>
              <td>
                <table className="results" border="0" cellPadding="10" cellSpacing="0">
                  <tbody>
                    {(Array.isArray(data) && data.length > 0) && data.map((product, index) => {
                      const {
                        name,
                        images,
                        metadata,
                        documentID
                      } = product;

                      return (
                        <tr key={index}>
                          <td>
                            <img className="thumb" src={images[0]} />
                          </td>
                          <td>
                            {name}
                          </td>
                          <td>
                            R${metadata.preco}
                          </td>
                          <td>
                            <ButtonForm onClick={() => dispatch(deleteProductStart(documentID))}>
                              Delete
                            </ButtonForm>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>

              </td>
            </tr>
            <tr>
              <td>
                <table border="0" cellPadding="10" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td>
                        {!isLastPage && (
                          <LoadMore {...configLoadMore} />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

      </div>

    </div>
  );
}

export default Admin;