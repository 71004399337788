import React, { useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaCartArrowDown, FaEye, FaCartPlus, FaSearch, FaMoneyBillWave } from "react-icons/fa";
import { fetchProductStart, setProduct } from './../../../redux/Products/products.actions';
import { addProduct } from './../../../redux/Cart/cart.actions';
import ButtonForm from './../forms/ButtonForm';
import './styles.scss';

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';

import Vinho from "../../assets/vinho.jpeg";
import Impressora from "../../assets/impressora.jpeg";
import Iphone from "../../assets/iphone.jpeg";
import BusValidator from "../../assets/busvalidator.jpeg";

const mapState = state => ({
  product: state.productsData.product
});

const ProductCard = ({ }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { productID } = useParams();
  const { product } = useSelector(mapState);
  
  const {
    images,
    name,
    description,
    stripe_metadata_preco,
    stripe_metadata_lote_id,
    metadata,
    productDesc,
  } = product;

  useEffect(() => {
    dispatch(
      fetchProductStart(productID)
    )

    return () => {
      dispatch(
        setProduct({})
      )
    }

  }, []);

  const handleAddToCart = (product) => {
    if (!product) return;
    dispatch(
      addProduct(product)
    );
    history.push('/cart');
  }

  const configAddToCartBtn = {
    type: 'button'
  };

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    // <div className="productCard">
    //   <div className="hero">
    //     <img src={images} />
    //   </div>
    //   <div className="productDetails">
    //     <ul>
    //       <li>
    //         <h1>
    //           {name}
    //         </h1>
    //       </li>
    //       <li>
    //         <h1>
    //           {description}
    //         </h1>
    //       </li>
    //       <li>
    //         <span>
    //           R${stripe_metadata_preco}
    //         </span>
    //       </li>
    //       <li>
    //         <div className="addToCart">
    //           <ButtonForm {...configAddToCartBtn} onClick={() => handleAddToCart(product)}>
    //             Adicionar ao carrinho
    //           </ButtonForm>
    //         </div>
    //       </li>
    //       <li>
    //         <span
    //           className="desc"
    //           dangerouslySetInnerHTML={{ __html: productDesc }} />
    //       </li>
    //     </ul>
    //   </div>
    // </div>
    <section className="margin-menu products-section">
      <Container>
        <Row>
          <div className="lote-title">
            <h1>Produto: {name}</h1>
          </div>
          <Col xs={8}>
            <div>
              { images ? <img className="img" src={images[0]} /> : <img /> }
            </div>
          </Col>
          <Col className="lote-details">
            <div>
              <h7><strong>Decsrição:</strong></h7>
              <p>{description}</p>
            </div>
            <div>
              <h7><strong>Preço:</strong></h7>
              <p>{formatter.format(stripe_metadata_preco)}</p>
            </div>
            <hr />
            <div>
              <h3>Adicionar ao Carrinho:</h3>
              <Button variant="primary" size="lg" onClick={() => handleAddToCart(product)}>
                  Adicionar ao Carrinho
              </Button>
            </div>
            <div>
              <h3>Visualizar Lote:</h3>
              <Button variant="primary" size="lg">
                <Link to={{ pathname: `/lote/${stripe_metadata_lote_id}` }}>
                  Visualizar Lote
                </Link>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ProductCard;