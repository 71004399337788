import React, { useEffect } from 'react';
import { UncontrolledCarousel } from 'reactstrap';
import { fetchLotesDestaqueStart } from './../../../redux/Lotes/lotes.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import './styles.scss';
import YoutubeIframe from '../YoutubeIframe';
import LoteDestaque from './LoteDestaque';

import {
  Row,
  Col,
  Form,
  Container,
  Button
} from 'react-bootstrap';
import { FaFilter, FaDollarSign, FaTag } from 'react-icons/fa';
import Select from 'react-select';
import Logo from "../../assets/logo.png";

const mapState = ({ lotesData }) => ({
  lotes: lotesData.lotes
});


const Directory = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { lotes } = useSelector(mapState);
  const { data, queryDoc, isLastPage } = lotes;

  useEffect(() => {
    dispatch(
      fetchLotesDestaqueStart(lotes)
    );
  }, []);

const options = [
  { value: 'eletronics', label: 'Eletrônicos' },
  { value: 'acessories', label: 'Acessórios' },
  { value: 'cars', label: 'Automóveis' }
]

  return (
    // <div className="directory">
    //   <h1 className='title'>Bem vindos a JoinBid, nós unimos para comprar!</h1>

    //   <div className="wrap">
    //     <div
    //       className="item"
    //     >
    //     </div>
    //     <div
    //       className="item"

    //     >
    //      <h1>Estes são os lotes em destaque.</h1>
    //      <div className='destaques'>
    //      {(Array.isArray(data) && data.length > 0) && data.map((lote, index) => {
    //       const {
    //         documentID,
    //         cidade,
    //         destaque,
    //         edital,
    //         tipo
    //       } = lote;
    //       const configLotes = {
    //         ...lote
    //       };

    //       return (
    //         <LoteDestaque key={index} {...configLotes} />
    //       );

    //     })}

    //      </div>
    //     </div>
    //   </div>
    // </div>
    <section class="margin-menu">
      <div className="text-center padding-top-section header">
        <img src={Logo} className="logo-branding" />  
      </div>
      <div className="padding-top-section">
        <div class="form-container">
          <Form>
            <Container>
              <Row className="border-bottom">
                <Col>
                  <Form.Label><FaFilter></FaFilter>&nbsp;&nbsp;Filtro</Form.Label>
                  <Form.Control size="lg" type="text"></Form.Control>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label><FaDollarSign></FaDollarSign>&nbsp;&nbsp;Valor máximo</Form.Label>
                  <Form.Control size="lg" type="text"></Form.Control>
                </Col>
                <Col className="border-left">
                  <Form.Label><FaTag></FaTag>&nbsp;&nbsp;Categoria</Form.Label>
                  <Select options={options}></Select>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
        <Container>
          <Row>
            <Col></Col>
            <Col>
              <aside className="text-center padding-top-less">
                <Button variant="primary" size="lg">Pesquisar</Button>
              </aside>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
      {/* <div className="text-center super-padding-top-section header">
        <h2>Fácil e sem burocracia</h2>
        <div className="text-header">
          <h4>Some text</h4>
          <p>Lorem Ipsum Dollor Sit Amet. Lorem Ipsum Dollor Sit Amet. Lorem Ipsum Dollor Sit Amet. Lorem Ipsum Dollor Sit Amet. Lorem Ipsum Dollor Sit Amet.</p>
          <a className="link" href="#">Saiba mais</a>
        </div>
      </div> */}
    </section>
  );
};

export default Directory;
