import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DepositoForm from '../../components/DepositoForm';
import './styles.scss';

const mapState = (user) => ({
  currentUser: user.currentUser,
});

const Depositar = props => {
  return (
    <div>
      <h1>
        Depositar Créditos Joinbid
      </h1>

      <DepositoForm />
    </div>
  );
};

export default Depositar;