import React from 'react';
import ProductsResults from '../../components/ProductsResult';

const Search = ({ }) => {
  return (
    <div className="searchPage">
      <ProductsResults />
    </div>
  );
};

export default Search;