import React, { Component } from 'react'
import "./styles.scss";
export default function FormInput({handleChange, label, ...otherProps}) {
    return(
      <div className='formrow'>
        {label && (
            <label className='labelInput'>
                {label}
            </label>
        )}
        <input className='forminput' onChange={handleChange}{...otherProps} />
      </div>
    )
}