import React, { useState } from 'react';
import FormInput from './../forms/FormInput';
import { CountryDropdown } from 'react-country-region-selector';
import { getCurrentUser, firestore } from '../../../firebase/utils';
import ButtonForm from '../forms/ButtonForm';
import { apiInstance } from '../../../Utils';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import { useEffect } from 'react';
import { auth } from './../../../firebase/utils'
import { useHistory } from 'react-router-dom';
import './styles.scss';

import {
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form
} from 'react-bootstrap';


const DepositoForm = () => {
  const history = useHistory();
  const [quantity, setQuantity] = useState(100)
  const [chavePix, setChavePix] = useState('')
  const [confirmChavePix, setConfirmChavePix] = useState('')
  const [err, setErr] = useState('')
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user)
        console.log(user)
        setLoading(false)
      } else {
        history.push('/login');
      }
    })
  }, [user])

  const handleSubmit = async () => {
    if (chavePix !== confirmChavePix || chavePix == '') {
      setErr('chaves não coincidem ou algum campo está vazio')
    } else if (quantity % 100 !== 0) {
      setErr('só é possível depositar em lotes de 100 JoinCreditos')
    }
    else {
      await apiInstance.post('/depositar', {
        user: user,
        qtd: quantity,
        chavePix: chavePix
      }).then((res) => { window.location.assign(res.data.ckeckout) })
    }
  }

  return (
    <div className='depositoDetalhes'>
      <h2>
        Você pode utilizar seus JoinCreditos assim que estiverem disponíveis
      </h2>
      <h3>
        Vantagens:
      </h3>
      <ul>
        <li>Isenção na taxa de participação de grupos de lance. (R$5,00 para compras até R$100, R$3,00 + 2% do valor da compra para valores de compra maiores do que R$100,00)</li>
        <li>Entrada e saída dos grupos em tempo real, sem necessidade de aguardar processamento ou devolução de pagamento</li>
        <li>Taxa 0 para devolução de valores em cartões de crédito.</li>
      </ul>

      <span style={{ color: 'red', fontSize: 'large' }}>{err}</span>
      <Form.Control
        placeholder="JoinCreditos"
        type="number"
        min="100.00"
        max="40000.00"
        step="100.00"
        value={quantity}
        onChange={e => setQuantity(e.target.value)}
      />
      <p>Taxa de depósito: R$4,00</p>
      <p>Os processamentos de pagamentos levam até 1 (um) dia útil</p>
      <Form.Control
        className="form-input"
        placeholder="Chave pix (necessária para saques de JoinCreditos)"
        type="text"
        value={chavePix}
        onChange={e => setChavePix(e.target.value)} />

      <Form.Control
        className="form-input"
        placeholder="Confirme sua chave pix"
        type="text"
        value={confirmChavePix}
        onChange={e => setConfirmChavePix(e.target.value)} />

      <Button variant="primary" id='btnDeposito' onClick={() => { handleSubmit() }}>
        Realizar depósito
      </Button>
    </div>
  )


}

export default DepositoForm;