import React, { useEffect, useState } from 'react';
import Directory from '../../components/Directory';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, useParams } from 'react-router-dom';
import { fetchLotesStart } from './../../../redux/Lotes/lotes.actions';
import { fetchAllProductsStart } from './../../../redux/Products/products.actions';
import './styles.scss'
import {
  Container,
  Row,
  Col,
  Button,
  Card
} from 'react-bootstrap';
import { FaCartArrowDown, FaCartPlus, FaSearch, FaMoneyBillWave } from "react-icons/fa";
import WhyBuy from "../../assets/why-buy.jpeg";

import Lote from './../../components/LotesResults/Lote';
import Product from './../../components/LotesResults/Lote/ProductResults/Product';

const mapState = ({ productsData, lotesData }) => ({
  products: productsData.products,
  lotes: lotesData.lotes
});

export default function HomePage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { products, lotes } = useSelector(mapState);
  const [loading, setLoading] = useState(false)

  const { data } = lotes;
  const dataProducts = products.data;

  useEffect(() => {
    setLoading(true)
    dispatch(
      fetchAllProductsStart(products),
      fetchLotesStart(lotes),
    );
    setLoading(false)
  }, []);

  return (
    <section className='homepage'>
      <Directory />
      <section className="lote-section">
        <Container className="padding-top-section">
          <Row>
            <Col className="text-center">
              <h1>Lotes</h1>
            </Col>
          </Row>
          <Container className="padding-top lote-box">
            <Row>
              {(Array.isArray(data) && data.length > 0) && data.map((lote, index) => {
                if (index < 4) {
                  return (<Lote col="3" key={index} {...lote} />);
                }
              })}
            </Row>
          </Container>
          <Container className="product-section padding-top">
            <Row>
              <Button variant="primary" size="lg">
                Buscar mais lotes
              </Button>
            </Row>
          </Container>
        </Container>
      </section>
      <section className="product-section">
        <Container className="padding-top-section">
          <Row>
            <Col className="text-center">
              <h1>Produtos</h1>
            </Col>
          </Row>
          <Container className="padding-top lote-box">
            <Row>
              {(Array.isArray(dataProducts) && dataProducts.length > 0) && dataProducts.map((product, index) => {
                if (index < 4) {
                  return (<Product key={index} {...product} />);
                }
              })}
            </Row>
          </Container>
        </Container>
      </section>
      <section className="why-buy-section">
        <Container>
          <div className="container-why-buy-box">
            <img className="why-buy-img" src={WhyBuy}></img>
            <div className="why-buy-box">
              Na Joinbid é garantia de satisfação e qualidade, sem burocracias e com experiência de E-Commerce.
            </div>
          </div>
          <div className="why-buy-text">
            <h1>Por quê comprar na <strong>JoinBid?</strong></h1>
            <div className="why-buy-p">
              <p>Praticidade de e-commerce</p>
              <p>Compra de itens individuais em lotes de leilões</p>
              <p>Preços abaixo do mercado</p>
              <p>Experiência do cliente em primeiro lugar</p>
            </div>
          </div>
          <Row>
            <Button variant="primary" size="lg">Ver mais produtos</Button>
          </Row>
        </Container>
      </section>
      <section className="padding-top-section how-it-works">
        <Container>
          <Row className="how-it-works-title">
            <div className="text-center">
              <h2>Como funciona a compra por lote?</h2>
            </div>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="how-it-works-block">
              <FaSearch className="icon box-1"></FaSearch>
              <h4>Encontre o que você deseja</h4>
              <p>Pesquise os produtos de seu interesse e encontre lotes que ofertam itens compatíveis com sua busca de forma fácil simple e intuitiva.</p>
            </Col>
            <Col className="how-it-works-block">
              <FaCartPlus className="icon box-2"></FaCartPlus>
              <h4>Dê seu lance</h4>
              <p>Assim que encontrar o que procura, garanta sua participação na compra do lote dando o seu lance e acompanhando este leilão.</p>
            </Col>
            <Col className="how-it-works-block">
              <FaMoneyBillWave className="icon box-3"></FaMoneyBillWave>
              <h4>Economize</h4>
              <p>Compre o que quer por um valor muito abaixo do mercado através das compras coletivas de lotes de leilão! O melhor dos dois mundos: barato e fácil!</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="help-section">
        <div className="text-center">
          <h1>Sobre a Joinbid</h1>
        </div>
        <Container>
          <Row>
            <Col>
              <Button variant="primary" size="lg">Quem somos</Button>
            </Col>
            <Col>
              <Button variant="primary" size="lg">Parcerias</Button>
            </Col>
            <Col>
              <Button variant="primary" size="lg">FAQ</Button>
            </Col>
            <Col>
              <Button variant="primary" size="lg">Fale com a gente</Button>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  )
}