import React from 'react';
import './styles.scss';
import Logo from "../../assets/output-onlinepngtools.png";

const LoadingSpinner = ()=>{
    return(
    <div className='loading' style={{width:'100%',height:'500px',textAlign:'center',display:'block'}}>
        <img  className='loading-img' src={Logo} style={{width:'80px',  position: 'absolute',
    margin: 'auto',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0'}}>
        
        </img>
    </div>
    )
}
export default LoadingSpinner